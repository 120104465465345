// App.js or index.js
import "react-native-gesture-handler";
import React from "react";
import { useEffect, useState, useContext, createContext } from "react";
import { Center, NativeBaseProvider, Box, Image, FormControl, Label, Button, Text } from "native-base";
import { NavigationContainer } from "@react-navigation/native";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { Amplify, Auth, Hub } from "aws-amplify";
// import awsconfig from "./aws-exports.js";
import { Navigator, UserContext } from './components/Navigator.js'
import * as config from "./config.json";

/* Amplify Setup for Login */
if (process.env.NODE_ENV == 'development') {
  Amplify.configure(config.cognito_config_development);
}
else if (process.env.NODE_ENV == 'production') {
  Amplify.configure(config.cognito_config_production);
}
else {
  console.log('STAGE is not set')
  Amplify.configure(config.cognito_config_development);
}

const App = () => {
  const [user, setUser] = useState(null);
  const [user_id, setUserId] = useState(null);
  const [id_token, setIdToken] = useState(null);
  const [customState, setCustomState] = useState(null);
  const [username, setUsername] = useState(null);
  const [email, setEmail] = useState(null);


  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      console.log('top', event, data)
      switch (event) {
        case "signIn":
          setUser(data);
          break;
        case "signOut":
          setUser(null);
          setUserId(null);
          setUsername(null);
          break;
        case "customOAuthState":
          setCustomState(data);
      }
    })

    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        setUser(currentUser);
        console.log('current user', currentUser)
        setUserId(currentUser.getUsername());
        Auth.userAttributes(currentUser)
          .then(attributes => {
            for (let i = 0; i < attributes.length; i++) {
              if (attributes[i]['Name'] == 'preferred_username') {
                setUsername(attributes[i]['Value']);
              }
              else if (attributes[i]['Name'] == 'email') {
                setEmail(attributes[i]['Value']);
              }

            }

          })
          .catch((err) => { console.log("Not authorize error"); console.log(err); })
      })
      .catch(() => console.log("Not signed in"));

    Auth.userAttributes()
      .then(attributes => setAttributes(attributes))

    Auth.currentSession().then(data => setIdToken(data.idToken));

    return unsubscribe;
  }, []);


  console.log("user_id", user_id)
  console.log("username", username)
  console.log("email", email)
  return (
    <UserContext.Provider value={{
      id_token: id_token,
      user_id: user_id,
      username: username,
      email: email,
      setUsername: setUsername,
    }}>
      <NativeBaseProvider>
        <NavigationContainer>
          <Navigator />
        </NavigationContainer>
      </NativeBaseProvider>
    </UserContext.Provider>
  );
};

export default App;