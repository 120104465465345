import { useWindowDimensions, TouchableOpacity } from "react-native";
import { DrawerActions } from "@react-navigation/native";
import { Ionicons } from '@expo/vector-icons';


export default function StackToggle({ navigation }) {
    const dimensions = useWindowDimensions();
    const isMobile = dimensions.width < 768;
    return isMobile ? (
        <TouchableOpacity onPress={() => navigation.dispatch(DrawerActions.toggleDrawer())} style={{ marginLeft: 10 }}>
            <Ionicons name="menu" size={30} />
        </TouchableOpacity>
    ) : null
}