import { Center, Image, Button, Text, View, Stack, Divider, Heading, Wrap } from "native-base";
import { useEffect, useState, useContext } from "react";
import { UserContext } from "../components/Navigator";
import { Dimensions, useWindowDimensions, ScrollView } from 'react-native';
import config from "../config.json";

function getWindowSize() {
    const { width, height } = useWindowDimensions();
    const isMobile = width < 768;
    return [width, height, isMobile];
}

export function VoteScreen({ route, navigation }) {
    const [targets, setTargets] = useState([{ "submission": "Loading" }]);
    const [currIdx, setCurrIdx] = useState(0);
    const [numVoted, setNumVoted] = useState();
    const user = useContext(UserContext);
    const contestInfo = route.params.info;
    console.log(process.env.API)

    // Get the window size inside the hook so it can respond to everytime the window changes.
    const [windowWidth, windowHeight, isMobile] = getWindowSize()
    // Set the image size to a certain percentage of the screen size
    const imageSize = {
        width: windowWidth * 0.95, // 90% of the screen width
        height: windowHeight * 0.4, // 30% of the screen height
    };

    function shuffle(array) {
        let currentIndex = array.length, randomIndex;
        while (currentIndex > 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    }
    useEffect(
        () => {
            const response = fetch(config.API_BASE + 'checkVotes', {
                method: 'POST',
                headers: { 'Authorization': user.id_token.getJwtToken(), },
                body: JSON.stringify({
                    exp_uid: contestInfo.exp_uid,
                    participant_id: user.user_id,
                })
            })
                .then((response) => response.json())
                .then((response) => { setNumVoted(response.votes); console.log(response.votes) })
                .catch(error => console.error(error));
        }, []);

    useEffect(() => {
        fetch(config.BUCKET + contestInfo.exp_uid + '/targets.json')
            .then(response => response.json())
            .then((json) => shuffle(json))
            .then(arr => { setTargets(arr) })
            .catch(error => console.error(error));
    }, []);

    function onVote(vote) {
        fetch(config.API_BASE + 'processAnswer', {
            method: 'POST',
            headers: { 'Authorization': user.id_token.getJwtToken(), },
            body: JSON.stringify({
                exp_uid: contestInfo.exp_uid,
                participant_id: user.user_id,
                target_id: currIdx,
                target_reward: vote
            })
        })
            .then(response => response.json())
            .catch(error => console.error(error));
        setCurrIdx(currIdx + 1);
        setNumVoted(numVoted + 1);
    };

    function finishScreen() {
        if (numVoted == 20) {
            alert("You have ran out of votes.");
        } else {
            alert("You have voted on all captions.");
        }
        navigation.navigate("Home");
    }

    return (numVoted !== undefined) && (
        <View>
            <ScrollView contentContainerStyle={{ flexGrow: 1, justifyContent: "center" }}>
                {numVoted >= Math.min(20, targets.length) ? (
                    finishScreen()
                ) : (
                    <Center>
                        <Image source={{ uri: config.BUCKET + contestInfo.exp_uid + "/cartoon.jpg" }} alt="Alternate Text"
                            style={{ width: imageSize.width, height: imageSize.height, resizeMode: 'contain' }} />
                        <Text italic fontFamily="Georgia, Times, serif" fontSize={"xl"} margin={6}>
                            {targets[currIdx]['submission']}
                        </Text>
                        <Divider my={3}></Divider>
                        <Stack direction={["column", "row"]} space={3}>
                            <Button backgroundColor="transparent" borderColor="black" borderWidth="2"
                                _text={{ color: "black", fontFamily: "Montserrat_700Bold", fontSize: "xl" }}
                                _hover={{ bgColor: "gray.400" }} // This will only work in web environment
                                _pressed={{ bgColor: "gray.400" }} // This will give feedback on touch in mobile
                                marginX="30"
                                onPress={() => onVote(3)}>
                                Funny
                            </Button>
                            <Button backgroundColor="transparent" borderColor="black" borderWidth="2"
                                _text={{ color: "black", fontFamily: "Montserrat_700Bold", fontSize: "xl" }}
                                _hover={{ bgColor: "gray.400" }}
                                _pressed={{ bgColor: "gray.400" }}
                                marginX="30"
                                onPress={() => onVote(2)}>
                                Somewhat Funny
                            </Button>
                            <Button backgroundColor="transparent" borderColor="black" borderWidth="2"
                                _text={{ color: "black", fontFamily: "Montserrat_700Bold", fontSize: "xl" }}
                                _hover={{ bgColor: "gray.400" }}
                                _pressed={{ bgColor: "gray.400" }}
                                marginX="30"
                                onPress={() => onVote(1)}>
                                Unfunny
                            </Button>
                        </Stack>
                        <Divider my={3} _light={{ bg: "muted.000" }}></Divider>
                        <Text fontFamily="Montserrat_400Regular">
                            You have {Math.min(20, targets.length) - numVoted} out of {Math.min(20, targets.length)} votes left.
                        </Text>
                    </Center>
                )}
            </ScrollView>
        </View>
    );
}

