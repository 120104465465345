import { Center, Box, Button, Image, Input, Text, View, Divider } from "native-base";
import CountDown from "../components/CountDown";
import { useEffect, useState, useContext } from "react";
import { UserContext } from "../components/Navigator";
import { Dimensions, SafeAreaView, useWindowDimensions, Platform, ScrollView } from 'react-native';
import config from "../config.json";


function getWindowSize() {
    const { width, height } = useWindowDimensions();
    const isMobile = width < 768;
    return [width, height, isMobile];
}

export function SubmitStartScreen({ route, navigation }) {
    const contestInfo = route.params.info;
    // Set the width based on the window width
    // Assuming 768 as a breakpoint for mobile devices
    // Get the window size inside the hook so it can respond to everytime the window changes.
    const [windowWidth, windowHeight, isMobile] = getWindowSize()
    const boxWidth = isMobile ? '80%' : '50%';
    // Set the image size to a certain percentage of the screen size
    const imageSize = {
        width: windowWidth * 0.95, // 90% of the screen width
        height: windowHeight * 0.4, // 30% of the screen height
    };

    return (
        <SafeAreaView style={{ flex: 1, }}>
            <ScrollView style={{ flex: 1 }}>
                <View style={{ alignItems: 'center' }}>
                    <Text fontFamily="Montserrat_700Bold" fontSize={"xl"} margin={5}>
                        Sumit Your Entry
                    </Text>
                    <Divider mb="4" thickness="5"></Divider>
                    <Image source={{ uri: config.BUCKET + contestInfo.exp_uid + "/cartoon_blur.jpg" }} alt="Cartoon"
                        style={{ width: imageSize.width, height: imageSize.height, resizeMode: 'contain' }} />
                    <Box alignItems="center" w={boxWidth}>
                        <Text marginY="10" fontSize="xl" textAlign="center" fontFamily="Montserrat_400Regular">
                            Welcome to {contestInfo.name}!{"\n"}
                            {contestInfo.rule}
                        </Text>
                    </Box>
                    <Button backgroundColor="transparent" borderColor="black" borderWidth="2"
                        _text={{ color: "black", fontFamily: "Montserrat_700Bold", fontSize: "xl" }}
                        onPress={() => {
                            navigation.navigate("SubmitEntry", { info: contestInfo })
                        }}>
                        Start!
                    </Button>
                </View>
            </ScrollView>
        </SafeAreaView>
    )
}

export function SubmitEntryScreen({ route, navigation }) {
    const [textInput, setTextInput] = useState("");
    const [height, setHeight] = useState(52);
    const [timeLeft, setTimeLeft] = useState();
    const user = useContext(UserContext);
    const contestInfo = route.params.info;
    // Set the width based on the window width
    // Assuming 768 as a breakpoint for mobile devices
    // Get the window size inside the hook so it can respond to everytime the window changes.
    const [windowWidth, windowHeight, isMobile] = getWindowSize()
    // Set the image size to a certain percentage of the screen size
    const imageSize = {
        width: windowWidth * 0.95, // 90% of the screen width
        height: windowHeight * 0.4, // 30% of the screen height
    };

    useEffect(
        () => {
            const response = fetch(config.API_BASE + 'checkSubmission', {
                method: 'POST',
                headers: { 'Authorization': user.id_token.getJwtToken(), },
                body: JSON.stringify({
                    exp_uid: contestInfo.exp_uid,
                    participant_id: user.user_id,
                })
            })
                .then((response) => response.json())
                .then((response) => { setTimeLeft(response.time_left); console.log(response.time_left) })
                .catch(error => console.error(error));
        }, [])

    function onSubmission() {
        fetch(config.API_BASE + 'addSubmission', {
            method: 'POST',
            headers: { 'Authorization': user.id_token.getJwtToken() },
            body: JSON.stringify({
                exp_uid: contestInfo.exp_uid,
                participant_id: user.user_id,
                username: user.username,
                submission: textInput,
                type: contestInfo.type
            })
        })
            .then(response => response.json())
            .catch(error => console.error(error));
    };

    return (timeLeft !== undefined) && (
        <SafeAreaView style={{ flex: 1, }}>
            <ScrollView style={{ flex: 1 }}>
                <View style={{ alignItems: 'center' }}>
                    <Text fontFamily="Montserrat_700Bold" fontSize={"xl"} margin={5}>
                        Sumit Your Entry
                    </Text>
                    <Divider mb="4" thickness="5"></Divider>
                    <Image source={{ uri: config.BUCKET + contestInfo.exp_uid + "/cartoon.jpg" }} alt="Cartoon"
                        style={{ width: imageSize.width, height: imageSize.height, resizeMode: 'contain' }} />
                    <Box alignItems="center" w="100%">
                        <Input
                            value={textInput}
                            placeholder={"Click Here to Enter"}
                            onChange={e => {
                                if (e.currentTarget.value.length < textInput.length) setHeight(52);
                                setTextInput(e.currentTarget.value);
                            }} // for web
                            onChangeText={text => setTextInput(text)} // for android and ios
                            multiline fontFamily="Georgia, Times, serif" fontStyle="italic"
                            w="90%" maxW="1000" borderWidth="0" textAlign="center" marginTop="6" marginBottom="8" fontSize="2xl" style={{ height: Math.max(height, 52) }}
                            onContentSizeChange={(event) => {
                                setHeight(event.nativeEvent.contentSize.height)
                            }}
                            _hover={{ borderColor: "gray.400", borderWidth: 2 }}
                            _focus={{ placeholder: "", borderColor: "gray.400", borderWidth: 2, backgroundColor: "white", focusOutlineColor: "gray.400" }}
                        />
                    </Box>
                    <View style={{ flexDirection: "row" }}>
                        <Button backgroundColor="transparent" borderColor="black" borderWidth="2"
                            _text={{ color: "black", fontFamily: "Montserrat_700Bold", fontSize: "xl" }}
                            marginX="30"
                            onPress={() => {
                                onSubmission();
                                console.log("Submitted: ", textInput);
                                navigation.navigate("Home")
                            }}>
                            Submit
                        </Button>
                        <CountDown
                            until={Math.max(0, timeLeft)}
                            marginTop={10}
                            size={20}
                            digitStyle={{ backgroundColor: "#FFF" }}
                            digitTxtStyle={{ color: "black" }}
                            timeToShow={["M", "S"]}
                            timeLabels={{ m: "Minutes", s: "Seconds" }}
                            onFinish={() => {
                                if (timeLeft >= 0) { alert("Sorry, you have ran out of time."); }
                                else { alert("Your participation to this contest has finished."); }
                                navigation.navigate("Home")
                            }}
                            showSeparator
                        />
                    </View>
                </View>
            </ScrollView>
        </SafeAreaView>
    );
}