import { Center, Box, Button, Image, Input, Text, View, Divider } from "native-base";
import CountDown from "../components/CountDown";
import { useEffect, useState, useContext } from "react";
import { UserContext } from "../components/Navigator";
import { Dimensions, SafeAreaView, useWindowDimensions, Platform, ScrollView } from 'react-native';
import { ContestPressable } from "../components/ContestPressable"
import { LeaderBoardEntry } from "../components/LeaderBoardEntry";
import { Auth } from "aws-amplify";
import config from "../config.json";

function getWindowSize() {
    const { width, height } = useWindowDimensions();
    const isMobile = width < 768;
    return [width, height, isMobile];
}


export function LeaderBoardScreen({ route, navigation }) {
    const user = useContext(UserContext);
    const [contests, setContests] = useState();
    const currTime = new Date(new Date().toLocaleString('en-US', { timeZone: "America/Los_Angeles" }));

    // Get the window size inside the hook so it can respond to everytime the window changes.
    const [, , isMobile] = getWindowSize()

    useEffect(() => {
        fetch(config.BUCKET + "contest_log.json", { cache: "no-cache" })
            .then(response => response.json())
            .then((json) => { setContests(json) })
            .catch(error => console.error(error));
    }, []);

    return (contests !== undefined) && (
        <SafeAreaView style={{ flex: 1 }}>
            <ScrollView style={{ flex: 1 }}>
                <View style={{ alignItems: 'center' }}>
                    <Text fontFamily="Montserrat_700Bold" fontSize={"xl"} margin={5}>
                        Finished Contests
                    </Text>
                    <Divider mb="4" thickness="5"></Divider>
                    <View style={{
                        flex: 1,
                        flexDirection: isMobile ? "column" : "row",
                        flexWrap: "wrap",
                        alignItems: "flex-start",
                        justifyContent: "space-evenly"
                    }}>
                        {contests.filter(contest => currTime > new Date(contest["EndTime"])).map(contest => (
                            <ContestPressable
                                key={"Leaderboard" + contest["exp_uid"]}
                                contestInfo={{
                                    name: contest["name"],
                                    exp_uid: contest["exp_uid"],
                                    stage: "LeaderBoardDisplay",
                                    type: contest["type"],
                                    timeLeft: "Finished",
                                    rule: contest["rule"]
                                }}
                                navigation={navigation}
                            />
                        ))}
                    </View>
                </View>
            </ScrollView>
        </SafeAreaView>

    );
}

export function LeaderBoardDisplay({ route, navigation }) {
    const contestInfo = route.params.info;
    const [leaderboard, setLeaderboard] = useState();

    // Get the window size inside the hook so it can respond to everytime the window changes.
    const [windowWidth, windowHeight, isMobile] = getWindowSize()

    // Set the image size to a certain percentage of the screen size
    const imageSize = {
        width: windowWidth * 0.95, // 90% of the screen width
        height: windowHeight * 0.4, // 30% of the screen height
    };

    useEffect(() => {
        fetch(config.BUCKET + contestInfo.exp_uid + '/leaderboard_with_usernames_partial.json')
            .then(response => response.json())
            .then((json) => { setLeaderboard(json) })
            .catch(error => console.error(error));
    }, []);
    return (leaderboard !== undefined) && (
        <SafeAreaView style={{ flex: 1 }}>
            <ScrollView style={{ flex: 1 }}>
                <Center>
                    <Image source={{ uri: config.BUCKET + contestInfo.exp_uid + "/cartoon.jpg" }} alt="Cartoon"
                        style={{ width: imageSize.width, height: imageSize.height, resizeMode: 'contain' }} />
                </Center >
                <View style={{
                    flex: 1,
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "flex-start",
                    justifyContent: "space-evenly",
                }}>
                    {leaderboard.map((entry, index) => (
                        <LeaderBoardEntry
                            key={index}
                            entryInfo={{
                                ranking: entry["stats"][7] + 1,
                                submission: entry["submission"],
                                stats: entry["stats"],
                                participants: entry["participants"]
                            }}
                        />
                    ))}
                </View>
            </ScrollView>
        </SafeAreaView>
    )
}