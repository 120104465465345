import { Box, Button, Text, View, Divider } from "native-base";
import { ContestPressable } from "../components/ContestPressable"
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useWindowDimensions, ScrollView, SafeAreaView } from 'react-native';
import config from "../config.json";
import {
    useFonts,
    Montserrat_300Light,
    Montserrat_400Regular,
    Montserrat_400Regular_Italic,
    Montserrat_700Bold
} from '@expo-google-fonts/montserrat';

export default function HomeScreen({ route, navigation }) {
    const [contests, setContests] = useState();
    const currTime = new Date(new Date().toLocaleString('en-US', { timeZone: "America/Los_Angeles" }));
    let [fontsLoaded] = useFonts({
        Montserrat_300Light,
        Montserrat_400Regular,
        Montserrat_400Regular_Italic,
        Montserrat_700Bold,
    });

    useEffect(() => {
        fetch(config.BUCKET + 'contest_log.json', { cache: "no-cache" })
            .then(response => response.json())
            .then((json) => { setContests(json) })
            .catch(error => console.error(error));
    }, []);

    function roundTime(time) {
        const timeSec = Math.floor(time / 1000);
        if (timeSec >= 60) {
            const timeMin = Math.floor(timeSec / 60);
            if (timeMin >= 60) {
                const timeHour = Math.floor(timeMin / 60);
                if (timeHour >= 24) {
                    const timeDay = Math.floor(timeHour / 60);
                    return timeDay.toString().concat(" days left")
                } else {
                    return timeHour.toString().concat(" hours left")
                }
            } else {
                return timeMin.toString().concat(" minutes left")
            }
        } else {
            return timeSec.toString().concat(" seconds left")
        }
    }

    return (contests !== undefined) && (
        <SafeAreaView style={{
            flex: 1,
            paddingTop: 50,
        }}>
            <ScrollView style={{ flex: 1 }}>
                <View style={{ alignItems: 'center' }}>
                    <Text fontFamily="Montserrat_700Bold" fontSize={"xl"} marginTop={5}>
                        Submission
                    </Text>
                    <View style={{
                        flex: 1,
                        flexDirection: "row",
                        flexWrap: "wrap",
                        alignItems: "flex-start",
                        justifyContent: "space-evenly",
                        marginBottom: 0,
                        paddingBottom: 0
                    }}>
                        {contests.filter(contest => (currTime > new Date(contest["LaunchSubmissionTime"])) &&
                            (currTime < new Date(contest["LaunchVotingTime"]))).map(contest => (
                                <ContestPressable
                                    key={"Home" + contest["exp_uid"]}
                                    contestInfo={{
                                        name: contest["name"],
                                        exp_uid: contest["exp_uid"],
                                        stage: "Submit",
                                        type: contest["type"],
                                        timeLeft: roundTime(new Date(contest["EndTime"]).getTime() - currTime.getTime()),
                                        rule: contest["rule"]
                                    }}
                                    navigation={navigation}
                                />
                            ))}
                    </View>
                    <Text fontFamily="Montserrat_700Bold" fontSize={"xl"} mt="2">
                        Vote
                    </Text>
                    <View style={{
                        flex: 1,
                        flexDirection: "row",
                        flexWrap: "wrap",
                        alignItems: "flex-start",
                        justifyContent: "space-evenly"
                    }}>
                        {contests.filter(contest => (currTime >= new Date(contest["LaunchVotingTime"])) &&
                            (currTime <= new Date(contest["EndTime"]))).map(contest => (
                                <ContestPressable
                                    key={contest["exp_uid"]}
                                    contestInfo={{
                                        name: contest["name"],
                                        exp_uid: contest["exp_uid"],
                                        stage: "Vote",
                                        type: contest["type"],
                                        timeLeft: roundTime(new Date(contest["EndTime"]).getTime() - currTime.getTime()),
                                        rule: contest["rule"]
                                    }}
                                    navigation={navigation}
                                />
                            ))}
                    </View>
                </View>
            </ScrollView>
        </SafeAreaView>

    );
}