import { Center, Box, Button, Image, Input, Text, View, Divider } from "native-base";
import CountDown from "../components/CountDown";
import { useEffect, useState, useContext } from "react";
import { UserContext } from "../components/Navigator";
import { Dimensions, SafeAreaView, useWindowDimensions, Platform, ScrollView } from 'react-native';
import { ContestPressable } from "../components/ContestPressable"
import { Auth } from "aws-amplify";
import config from "../config.json";

function getWindowSize() {
    const { width, height } = useWindowDimensions();
    const isMobile = width < 768;
    return [width, height, isMobile];
}

export function ResultScreen({ route, navigation }) {
    const user = useContext(UserContext);
    const [results, setResults] = useState();

    useEffect(() => {
        fetch(config.API_BASE + 'participantInfo',
            {
                method: 'POST',
                headers: { 'Authorization': user.id_token.getJwtToken(), },
                body: JSON.stringify({
                    participant_id: user.user_id,
                })
            })
            .then(response => response.json())
            .then((json) => { setResults(json["ranks"]) })
            .catch(error => console.error(error));
    }, []);

    console.log(results);

    return (results !== undefined) && (
        <SafeAreaView style={{ flex: 1 }}>
            <ScrollView style={{ flex: 1 }}>
                <View style={{ alignItems: 'center' }}>
                    <Text fontFamily="Montserrat_700Bold" fontSize={"xl"} margin={5}>
                        Your Past Contests
                    </Text>
                    <Divider mb="4" thickness="5"></Divider>
                    <View style={{
                        flex: 1,
                        flexDirection: "row",
                        flexWrap: "wrap",
                        alignItems: "flex-start",
                        justifyContent: "space-evenly"
                    }}>
                        {results.map(result => (
                            <ContestPressable
                                key={"Results" + result["exp_uid"]}
                                contestInfo={{
                                    name: result["submission"],
                                    exp_uid: result["exp_uid"],
                                    stage: "ResultDisplay",
                                    type: "",
                                    timeLeft: "Finished",
                                    imgURL: "",
                                    imgURLBlur: "",
                                    rule: "",
                                    rank: result["rank"] + 1
                                }}
                                navigation={navigation}
                            />
                        ))}
                    </View>
                </View>
            </ScrollView>
        </SafeAreaView>

    );
}

export function ResultDisplay({ route, navigation }) {
    const user = useContext(UserContext);
    const resultInfo = route.params.info;
    // Get the window size inside the hook so it can respond to everytime the window changes.
    const [windowWidth, windowHeight, ,] = getWindowSize()
    // Set the image size to a certain percentage of the screen size
    const imageSize = {
        width: windowWidth * 0.95, // 90% of the screen width
        height: windowHeight * 0.4, // 30% of the screen height
    };
    return (
        <SafeAreaView style={{ flex: 1 }}>
            <ScrollView style={{ flex: 1 }}>
                <Center>
                    <Image source={{ uri: config.BUCKET + resultInfo.exp_uid + "/cartoon.jpg" }} alt="Cartoon"
                        style={{ width: imageSize.width, height: imageSize.height, resizeMode: 'contain' }} />
                    <Text italic fontFamily="Georgia, Times, serif" fontSize={"xl"} margin={6}>
                        {resultInfo.name}
                    </Text>
                    <Text fontFamily="Georgia, Times, serif" fontSize={"xl"}>
                        by  {user.username}
                    </Text>
                    <Divider marginTop={10} marginBottom={3}></Divider>
                    <Text fontFamily="Montserrat_400Regular_Italic" fontSize={"xl"}>
                        <b>Ranking</b>: #{resultInfo.rank}
                    </Text>
                </Center >
            </ScrollView>
        </SafeAreaView>
    )
}