import { AspectRatio, Box, Image, Stack, HStack, Text, Center, Heading, ScrollView } from "native-base";

export function LeaderBoardEntry({ entryInfo }) {
    return (
        <Box alignItems="center" margin="3">
            <Box width="80" height="200" rounded="lg" overflow="hidden" borderColor="coolGray.200" borderWidth="1" _dark={{
                borderColor: "coolGray.600",
                backgroundColor: "gray.700"
            }} _web={{
                shadow: 2,
                borderWidth: 0
            }} _light={{
                backgroundColor: "gray.50"
            }}>
                <ScrollView>
                    <Stack p="4" space={3}>
                        <Stack space={2}>
                            <Heading size="md" ml="-1" fontFamily="Montserrat_400Regular">
                                #{entryInfo.ranking}.  {entryInfo.submission}
                            </Heading>
                            <Text fontSize="xs"
                                _light={{
                                    color: "violet.500"
                                }}
                                _dark={{
                                    color: "violet.400"
                                }}
                                fontWeight="500" ml="-0.5" mt="-1"
                                fontFamily="Montserrat_400Regular"
                            >
                                by {entryInfo.participants.map(participant => participant[1]).join(", ")}
                            </Text>
                        </Stack>
                        <HStack alignItems="center" space={4} justifyContent="space-between">
                            <HStack alignItems="center">
                                <Text
                                    color="coolGray.600"
                                    _dark={{ color: "warmGray.200" }}
                                    fontWeight="400"
                                    fontFamily="Montserrat_400Regular"
                                >
                                    <Text fontFamily="Montserrat_700Bold" >Funny: </Text>{entryInfo.stats[4]}   <Text fontFamily="Montserrat_700Bold">Somewhat: </Text>{entryInfo.stats[5]}   <Text fontFamily="Montserrat_700Bold">Not: </Text>{entryInfo.stats[6]}
                                </Text>
                            </HStack>
                        </HStack>
                    </Stack>
                </ScrollView>
            </Box>
        </Box>
    );
}

