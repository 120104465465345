import { Pressable, Box, Image, Spacer, Badge, HStack, Text, Center } from "native-base";
import { Auth } from "aws-amplify";
import { useContext } from "react";
import { UserContext } from "./Navigator";
import config from "../config.json";

export function ContestPressable({ contestInfo, navigation }) {
    const user = useContext(UserContext);
    return (
        <Box alignItems="center" marginX="5" marginY="5">
            <Pressable width="72" onPress={() => {
                if (user.user_id == null) Auth.federatedSignIn();
                else navigation.navigate(contestInfo.stage, { info: contestInfo })
            }}>
                {({
                    isHovered,
                    isFocused,
                    isPressed
                }) => {
                    return <Box bg={isPressed ? "coolGray.200" : isHovered ? "coolGray.200" : "coolGray.100"} style={{
                        transform: [{
                            scale: isPressed ? 0.96 : 1
                        }]
                    }} p="5" rounded="8" shadow={3} borderWidth="1" borderColor="coolGray.300">
                        <HStack alignItems="center" py="3">
                            {(contestInfo.type == "") ? (null) :
                                (<Badge colorScheme={(contestInfo.type == "Speed") ? ("red") : ("darkBlue")} _text={{
                                    color: "white"
                                }} variant="solid" rounded="4">
                                    {contestInfo.type}
                                </Badge>)}
                            <Spacer />
                            <Text fontSize={10} color="coolGray.800" fontFamily="Montserrat_400Regular">
                                {contestInfo.timeLeft}
                            </Text>
                        </HStack>
                        <Center>
                            <Image source={(contestInfo.stage == "Vote" ||
                                contestInfo.stage == "LeaderBoardDisplay" ||
                                contestInfo.stage == "ResultDisplay") ?
                                ({ uri: config.BUCKET + contestInfo.exp_uid + "/cartoon.jpg" }) :
                                ({ uri: config.BUCKET + contestInfo.exp_uid + "/cartoon_blur.jpg" })}
                                alt="Image Loading..." style={{ width: 250, height: 125 }}></Image>
                        </Center>
                        <Text color="coolGray.800" mt="3" fontWeight="medium" fontSize="xl" fontFamily="Montserrat_300Light">
                            {contestInfo.name}
                        </Text>
                    </Box>;
                }}
            </Pressable >
        </Box >
    );
}