import { Text, Image, View, Center } from 'native-base';
import { useFonts, Montserrat_800ExtraBold } from '@expo-google-fonts/montserrat';
import StackToggle from './StackToggle';

export default function LogoTitle(props) {
    let [fontsLoaded] = useFonts({
        Montserrat_800ExtraBold,
    });
    return (
        <View style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            shadowColor: '#171717',
            shadowOffset: { width: -2, height: 4 },
            shadowOpacity: 0.2,
            shadowRadius: 3,
        }}
        >
            <View style={{
                flex: 1, flexDirection: "row", justifyContent: "flex-start", position: 'absolute', left: 0, zIndex: 1
            }}>
                <StackToggle navigation={props.navigation} />
            </View>
            <View style={{
                flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center"
            }}>
                <Image
                    style={{ width: 50, height: 50 }}
                    source={require('../assets/logo.png')}
                />
                <Text fontSize="2xl" fontFamily="Montserrat_800ExtraBold"> R2Dtoon</Text>
            </View>
        </View >
    );
}

