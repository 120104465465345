import { createDrawerNavigator, DrawerContentScrollView, DrawerItem } from '@react-navigation/drawer'
import { useWindowDimensions, TouchableOpacity } from "react-native"
import { Auth } from "aws-amplify";
import { VoteScreen } from '../screens/VoteScreen.js'
import ProfileScreen from '../screens/ProfileScreen.js'
import HomeScreen from "../screens/HomeScreen.js";
import { LeaderBoardScreen, LeaderBoardDisplay } from '../screens/LeaderBoardScreen.js';
import { SubmitStartScreen, SubmitEntryScreen } from "../screens/SubmitScreen.js";
import { ResultScreen, ResultDisplay } from '../screens/ResultScreen.js';
import { useContext, createContext } from "react";
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Ionicons, FontAwesome } from '@expo/vector-icons';
import { SafeAreaView } from 'react-native-safe-area-context';
import LogoTitle from './LogoTitle.js';
import StackToggle from './StackToggle.js';

/* User Context */
export const UserContext = createContext(null);

function MainStack({ navigation }) {
    const Stack = createNativeStackNavigator();
    const user = useContext(UserContext);
    return (
        <Stack.Navigator
            screenOptions={{
                headerTitle: "",
                headerTransparent: true,
                headerLeft: () => (<StackToggle navigation={navigation} />)
            }}
        >
            <Stack.Screen name="Home" component={HomeScreen}
                options={{
                    header: (props) => <LogoTitle {...props} />,
                }}
            />
            <Stack.Screen name="Vote" component={VoteScreen} />
            <Stack.Screen name="Submit" component={SubmitStartScreen} />
            <Stack.Screen name="SubmitEntry" component={SubmitEntryScreen} />
            <Stack.Screen name="Profile" component={ProfileScreen} />
            <Stack.Screen name="LeaderBoard" component={LeaderBoardScreen} />
            <Stack.Screen name="LeaderBoardDisplay" component={LeaderBoardDisplay} />
            <Stack.Screen name="Result" component={ResultScreen} />
            <Stack.Screen name="ResultDisplay" component={ResultDisplay} />
        </Stack.Navigator>
    );
}



export function Navigator() {
    const dimensions = useWindowDimensions();
    const Drawer = createDrawerNavigator();
    const user = useContext(UserContext);


    // Style adjustments for mobile devices
    const isMobile = dimensions.width < 768;
    const isWeb = dimensions.width >= 768; // You can adjust this breakpoint as needed
    const drawerWidthWeb = 280; // Fixed drawer width for web
    const drawerWidthMobile = dimensions.width * 0.6; // Drawer takes up 80% of the screen width on mobile
    const drawerWidth = isWeb ? drawerWidthWeb : drawerWidthMobile;

    const drawerStyle = {
        width: drawerWidth, // Set the drawer width
        ...(isMobile && { marginTop: 20 }), // Add top margin on mobile devices
    };

    return (
        <Drawer.Navigator
            screenOptions={{
                drawerType: isWeb ? 'permanent' : 'front',
                headerShown: false,
                overlayColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent overlay
                drawerStyle: {
                    width: drawerWidth, // Set the drawer width
                },
            }}
            initialRouteName="Home"
            drawerContent={props => {
                return (
                    <SafeAreaView edges={['top', 'left', 'right']} style={{ flex: 1 }}>
                        <DrawerContentScrollView {...props} contentContainerStyle={{ flex: 1 }}>
                            {/* <VStack space="6" my="10" mx="1"> */}
                            {/* <Box px="4">
                                <Text bold color="gray.700">Menu</Text>
                            </Box>
                            <Divider /> */}
                            <DrawerItem
                                label="Home"
                                onPress={() => { props.navigation.navigate("Home") }}
                                icon={({ color, size }) => (
                                    <FontAwesome name="home" color={color} size={size} style={{ marginRight: 10 }} />
                                )}
                            />
                            <DrawerItem
                                label="Leaderboard"
                                onPress={() => { props.navigation.navigate("LeaderBoard") }}
                                icon={({ color, size }) => (
                                    <FontAwesome name="trophy" color={color} size={size} style={{ marginRight: 10 }} />
                                )}
                            />
                            {user.user_id == null ? (
                                null
                            ) : (
                                <DrawerItem
                                    label="My Performance"
                                    onPress={() => { props.navigation.navigate("Result") }}
                                    icon={({ color, size }) => (
                                        <FontAwesome name="line-chart" color={color} size={size} style={{ marginRight: 5 }} />
                                    )}
                                />
                            )}
                            {user.user_id == null ? (
                                null
                            ) : (
                                <DrawerItem
                                    label="Profile"
                                    onPress={() => { props.navigation.navigate("Profile") }}
                                    icon={({ color, size }) => (
                                        <FontAwesome name="user" color={color} size={size} style={{ marginRight: 15 }} />
                                    )} />
                            )}
                            {user.user_id == null ? (
                                <DrawerItem
                                    label="Login / Signup"
                                    onPress={() => { Auth.federatedSignIn().then((c) => console.log('at sign in', c)); props.navigation.navigate("Home") }}
                                    icon={({ color, size }) => (
                                        <FontAwesome name="sign-in" color={color} size={size} style={{ marginRight: 11 }} />
                                    )} />
                            ) : (
                                <DrawerItem
                                    label="Logout"
                                    onPress={() => { Auth.signOut(); props.navigation.navigate("Home") }}
                                    icon={({ color, size }) => (
                                        <FontAwesome name="sign-out" color={color} size={size} style={{ marginRight: 10 }} />
                                    )} />
                            )}
                            {/* </VStack> */}
                        </DrawerContentScrollView>
                    </SafeAreaView >
                )
            }}>
            <Drawer.Screen name="MainStack" component={MainStack} />
        </Drawer.Navigator>
    );
}

