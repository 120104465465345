import { Center, Text, Input, Button, View, Divider } from "native-base";
import { UserContext } from "../components/Navigator";
import { useEffect, useState, useContext } from "react";
import { SafeAreaView, useWindowDimensions, ScrollView } from 'react-native';
import config from "../config.json";
import { Auth } from 'aws-amplify';

export default function ProfileScreen({ route, navigation }) {
    const user = useContext(UserContext);
    const [usernameInput, setUsernameInput] = useState();
    const window = useWindowDimensions();
    const isMobile = window.width < 768;

    async function updateUsername() {
        try {
            const curUser = await Auth.currentAuthenticatedUser();
            const result = await Auth.updateUserAttributes(curUser, {
                preferred_username: usernameInput
            });
            console.log(user.setUsername);
            user.setUsername(usernameInput);
            console.log(result); // SUCCESS
        } catch (err) {
            console.log(err);
            alert("Sorry, we failed to update your preferred username. Please try again.")
        }
    };

    async function changePassword(oldPassword, newPassword) {
        try {
            const curUser = await Auth.currentAuthenticatedUser();
            const data = await Auth.changePassword(curUser, oldPassword, newPassword);
            console.log(data);
        } catch (err) {
            console.log(err);
            alert("Sorry, we failed to update your preferred username. Please try again.")
        }
    };

    return (
        <SafeAreaView style={{ flex: 1 }}>
            <ScrollView contentContainerStyle={{ flexGrow: 1, justifyContent: "center" }}>
                <Center style={{ flex: 1 }}>
                    <Text fontSize="lg" textAlign="center" fontFamily="Montserrat_400Regular">
                        Welcome {user.username}!{"\n"}
                        Your email is {user.email}.
                    </Text>
                    <View my="6" style={{ flexDirection: isMobile ? "column" : "row", alignItems: isMobile ? "stretch" : "center" }}>
                        <Text fontSize="xl" mb={isMobile ? "2" : "0"} fontFamily="Montserrat_400Regular"> Preferred Username: </Text>
                        <Input
                            w={isMobile ? "100%" : "80"}
                            py="0"
                            InputRightElement={
                                <Button size="xs" rounded="none" w="1/6" h="full" onPress={updateUsername}>
                                    Update
                                </Button>}
                            placeholder={user.username}
                            onChange={e => { setUsernameInput(e.currentTarget.value); }} // for web
                            onChangeText={text => setUsernameInput(text)}
                        />
                    </View>
                </Center>
            </ScrollView>
        </SafeAreaView>
    );
}
